import {SET_FULL_REPORT} from '../actions/types';

export type ReportState = {
  fullReport: Array<any>;
};

export const initialState: ReportState = {
  fullReport: [],
};

const fullReport = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FULL_REPORT: {
      return {
        ...state,
        fullReport: action.payload,
      };
    }
    default:
      return state;
  }
};

export default fullReport;
