import React, {useEffect, useState} from 'react';
import type {RadioChangeEvent} from 'antd';
import {Radio, Tabs, Typography} from 'antd';
import {useSelector} from 'react-redux';
import {reducersState} from '../redux/reducers';
import {ReportContent} from '../modules/Report';
import {Classschool} from '../graphql/API';
import moment from 'moment';
import {StatisticRepository} from '../modules/Report/StatisticRepository';
type TabPosition = 'left' | 'right' | 'top' | 'bottom';

const TabsHeader = () => {
  const fullReport = useSelector(
    (state: reducersState) => state.fullReport.fullReport,
  );

  const {Text} = Typography;
  const [mode, setMode] = useState<TabPosition>('top');
  const [months, setMonths] = useState<any>(moment().format('YYYY-MM'));
  const [selectedMonth, setSelectedMonth] = useState<any>([]);
  const [classes, setClasses] = useState([]);

  const statisticRepository = new StatisticRepository();
  const generateMonthsTabs = (classId: string) => {
    return months.map((month, index) => ({
      label: (
        <Text
          style={{
            display: 'block',
            fontFamily: 'Roboto',
            color: '#999999',
            fontSize: 16,
          }}>
          {moment(month, 'YYYY-MM').format('MMM YYYY')}
        </Text>
      ),
      key: month,
      children: (
        <ReportContent
          classId={classId}
          selectedMonth={month}
          monthIndex={index}
        />
      ),
    }));
  };

  const currentUser = useSelector(
    (state: reducersState) => state.user?.currentUser,
  );

  const fetchThePrevReportsMonthsBuyYears = (fullReport) => {
    let months: string[] = [];
    const startOfYear = moment().startOf('year');
    const currentMonth = moment();

    while (startOfYear <= currentMonth) {
      months.push(startOfYear.format('YYYY-MM'));
      startOfYear.add(1, 'month');
    }
    //@ts-ignore
    if (
      fullReport &&
      fullReport.monthlyReports &&
      fullReport.monthlyReports.length > 0
    ) {
      //@ts-ignore
      fullReport.monthlyReports.map((report: any) => {
        const createdMonth = moment(report.month);
        const formattedDate = createdMonth.format('YYYY-MM');
        months.push(formattedDate);
      });
    }
    months = Array.from(new Set(months));
    months.sort((a, b) => moment(a, 'YYYY-MM').diff(moment(b, 'YYYY-MM')));
    return months;
  };
  const fetchThePrevReportsMonths = (fullReport) => {
    const monthsArr = fetchThePrevReportsMonthsBuyYears(fullReport);
    if (!monthsArr) return;
    setSelectedMonth({
      month: moment().format('YYYY-MM'),
      index: monthsArr.indexOf(moment().format('YYYY-MM')),
    });
    setMonths(monthsArr);
  };

  useEffect(() => {
    fetchThePrevReportsMonths(fullReport);
  }, [fullReport]);

  useEffect(() => {
    currentUser?.teacher
      ? setClasses(currentUser?.teacher?.classesschool)
      : setClasses(currentUser?.school?.classesschool);
  }, [currentUser?.school, currentUser?.teacher]);

  const handleTabChange = (key: string) => {
    setSelectedMonth({
      month: key,
      index: months.indexOf(key),
    });
  };
  const fetchNewReports = async (id: string) => {
    try {
      const report = await statisticRepository.getFullReport(id);
      fetchThePrevReportsMonths(report);
    } catch (e) {
      console.log('error', e);
    }
  };
  return (
    <div style={{flex: 1}}>
      {selectedMonth && (
        <Tabs
          defaultActiveKey="1"
          tabPosition={mode}
          centered
          style={{height: 220}}
          onTabClick={(e: any) => {
            console.log('onTabClick', e);
          }}
          items={classes?.map((classSchool: Classschool, index) => ({
            label: (
              <Text
                onClick={() => {
                  fetchNewReports(classSchool.statistic.id);
                }}
                className="fw-base"
                style={{
                  display: 'block',
                  fontFamily: 'Roboto',
                  color: '#999999',
                  fontSize: 16,
                }}>
                {`Class ${classSchool?.name}`}
              </Text>
            ),
            key: classSchool.id,
            children: (
              <Tabs
                defaultActiveKey={selectedMonth.month}
                centered
                onChange={handleTabChange}
                tabPosition={mode}
                items={generateMonthsTabs(classSchool.id)}
              />
            ),
          }))}
        />
      )}
    </div>
  );
};

export default TabsHeader;
