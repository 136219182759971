import {SET_FULL_REPORT} from './types';

export const setFullReport = (monthlyReport: Array<any>) => {
  return {type: SET_FULL_REPORT, payload: monthlyReport};
};

export const setFullReports = (fullReport) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setFullReport(fullReport));
    } catch (error: any) {
      console.log('error', error);
    }
  };
};
